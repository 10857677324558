import React, { useState, useEffect } from 'react';
import Layout from '../../components/Layout';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { onSearchChange } from '../../state/reducers/common';

import { graphql } from 'gatsby';
import Product from '../../components/Product';
import PageTitle from '../../components/PageTitle/PageTitle';
import _ from 'lodash';

const SearchResultList = styled.div`
  @media screen and (min-width: 768px) {
    margin-top: 50px;
  }
`;

const Search = ({ pageContext, data, location }) => {
  const { t } = useTranslation();
  const allProducts = data.allProduct.nodes;
  const gender = pageContext.gender;
  const genderPath = gender === 'men' ? '/men' : '';
  const { isMobileView, searchValue } = useSelector((state) => state.common);
  let [result, setResult] = useState([]);
  const dispatch = useDispatch();

  function onFilterResult(text) {
    if (text !== null && text !== '') {
      const filteredResult = _.filter(allProducts, function (r) {
        // console.log(r.content.name);

        return r.content.name.toLowerCase().includes(text.toLowerCase());
      });
      setResult(filteredResult);
    }
  }

  useEffect(() => {
    onFilterResult(searchValue);
    return () => {
      dispatch(onSearchChange(null));
    };
  }, [searchValue]);

  // CHECK URL QUERY PARAMS
  useEffect(() => {
    const urlParmas = new URLSearchParams(location.search).get('q');
    if (urlParmas !== null) {
      onFilterResult(urlParmas);
    }
  }, []);

  return (
    <Layout fullwidth={isMobileView} seoSettings={{ title: t('search') }}>
      <PageTitle show>{t('search_result', { num: result.length })}</PageTitle>
      <SearchResultList className={`row no-gutters `}>
        {result.map((node) => {
          return (
            <Product
              key={node.id}
              id={node.id}
              classes={{ container: 'col-md-4 col-sm-6' }}
              image={node.remoteImage.childImageSharp.fluid}
              gender={gender}
              title={node.content.name}
              description={node.content.short_description}
              path={`${genderPath}/product/${node.slug}`}
            />
          );
        })}
      </SearchResultList>
    </Layout>
  );
};

export default Search;

export const pageQuery = graphql`
  query($locale: String, $gender: String) {
    allProduct(
      filter: { genders: { elemMatch: { slug: { eq: $gender } } } }
      sort: { order: ASC, fields: [series___sequence, sequence] }
    ) {
      nodes {
        id
        content(locale: $locale) {
          name
          short_description
        }

        slug

        remoteImage {
          childImageSharp {
            fluid(maxWidth: 105) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
